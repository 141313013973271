var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"2385058ddefb17c2b0797145c90c8e126be0db4e"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import {
  standardSentryConfig,
  extraClientConfig,
} from '@mentimeter/sentry-config';

Sentry.init({
  dsn:
    globalThis.__mentimeterEnv['NEXT_PUBLIC_PAGES_ROUTER'] === 'true'
      ? 'https://acefa0c4155042c7a28a0fae734bb32d@o866780.ingest.sentry.io/5830343'
      : 'https://490613dcab92107eb636ffff0121af01@o866780.ingest.sentry.io/4506398899306496',
  ...standardSentryConfig,
  ...extraClientConfig,
  // This MUST be here as overwrite in the client config for env to be set with next-env-vars
  // Can be removed when this application is cf pages only
  environment: globalThis.__mentimeterEnv['NEXT_PUBLIC_ENVIRONMENT_NAME'] ?? 'dev',
  tracesSampleRate: 0,
});
